<template>
<div class="about">
    <div class="sideContact">
            <div class="sideContactTit"><a href="javascript:;">联系我们</a></div>
            <div class="sideContactBox">
                <div v-for="(item,i) in configs" :key="i"> {{item.value}}</div>
            </div>
        </div>
</div> 
</template>

<script>
export default {
  props: {
    msg: String
  },
  data() {
    return {
    configs:"",
    }
  },
  created(){
    this.getIndexDate()
    
  },
  methods:{
    getIndexDate(){
        let configs = window.localStorage.getItem('configs')
        this.configs = JSON.parse(configs)
        console.log(configs)
      },
    
  },
  mounted(){

  },
  beforeUpdate(){
    this.getIndexDate()

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped >
  

</style>
